// Import our custom CSS
import '../scss/theme.scss'

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';




/*
 * NAVBAR - MOSTRA LA NAVBAR DURANTE LO SCROLL VERSO L'ALTO
 */
function navbarMoveToDownWhenScrollingUp(){
	
    document.addEventListener("DOMContentLoaded", function() {
        
        var staticLogo = document.querySelector('.logo--static');
        var scrolledLogo = document.querySelector('.logo--scrolled');
        var navbar = document.querySelector("header.js--navbarScrollingUp");

        if(navbar){

            let windowHeight = window.innerHeight;
            var navbarHeight = navbar.offsetHeight;
            var prevScrollpos = window.pageYOffset;
            var scrolledDown = false;

            window.onscroll = function() {
                var currentScrollPos = window.pageYOffset;
                // console.log(windowHeight);
                if (currentScrollPos > windowHeight) {
                    scrolledDown = true;
                    document.body.classList.add("is-scrolled");
                } else {
                    scrolledDown = false;
                    document.body.classList.remove("is-scrolled");
                }

                if (scrolledDown) {
                    if (prevScrollpos > currentScrollPos) {
                        navbar.style.top = '0';
                    } else {
                        navbar.style.top = '-' + navbarHeight + 'px';
                    }
                }

                prevScrollpos = currentScrollPos;
            };
        }

        if (scrolledLogo) {
            window.addEventListener('scroll', function() {
                var scrollPosition = window.scrollY || window.pageYOffset;
        
            if (scrollPosition > 200) {
                gsap.to(staticLogo, { opacity: 0, duration: 0.35, display: "none" },0);
                gsap.to(scrolledLogo, { opacity: 1, duration: 0.35, display: "block" },0);
            } else if(scrollPosition <= 0) {
                gsap.to(staticLogo, { opacity: 1, duration: 0.35, display: "block" },0);
                gsap.to(scrolledLogo, { opacity: 0, duration: 0.35, display: "none" },0);
            }
            });
        };
        
    });

}
navbarMoveToDownWhenScrollingUp();




var swiper = new Swiper(".swiper--products", {
    slidesPerView: 3,
    autoplay: {
        delay: 3000
    },
    spaceBetween: 32,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        991: {
          slidesPerView: 3,
        }
      },
});

jQuery(function ($) {
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        var fullHeight = $(window).height();
        var header = $("header.header");
  
         //>=, not <=
        if (scroll >= fullHeight) {
            //clearHeader, not clearheader - caps H
            header.addClass("is-scrolled");
        } else {
            header.removeClass("is-scrolled");
        }
    }); //missing );



    $( '.woocommerce-product-gallery' ).each( function() {
		console.log(this);
        
	} );


}); // jQuery End


function addClassToBody(){

    const btnOpen = document.querySelector('.btn-menu-mobile');
    const btnClose = document.querySelector('.btn-close');
    const body = document.body;

    function openOffcanvas() {
        body.classList.add('is-opened');
    }

    // Funzione per chiudere l'offcanvas e rimuovere la classe dal body
    function closeOffcanvas() {
        body.classList.remove('is-opened');
    }

    // Event listener per il pulsante di apertura
    btnOpen.addEventListener('click', openOffcanvas);

    // Event listener per il pulsante di chiusura
    btnClose.addEventListener('click', closeOffcanvas);
    
}
addClassToBody();